import NavButton from '../nav-button/NavButton';
import './NavSection.css';
import { useState } from 'react';

export const NavSection = ({ text, path, nested }) => {
    if (nested === undefined) nested = {};
    const [visible, setVisible] = useState(false);
    return (
        <div className="nav-item">
            <div className='top-nav' onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
              <NavButton text={text} path={path}/>
            </div>
            <div className={'nested-nav' + (visible ? ' visible' : '')} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
              { Object.entries(nested).map(([text, path]) => <NavButton key={path} text={text} path={path}/>) }
            </div>
        </div>
      )
}

export default NavSection;