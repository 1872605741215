import ApiService from '../../../services/ApiService'
import { useState, useEffect } from 'react'
import OrgButton from './org-button/OrgButton'
import { useNavigate } from 'react-router-dom'
import './OrgButtons.css'

export const OrgButtons = ({ link, filterFunc }) => {

    if (filterFunc === undefined) filterFunc = (org) => { return true }

    const [orgs, setOrgs] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => ApiService.getOrgs(setOrgs), []);

    let nav = useNavigate();

  return (
    <>
      <div id="org-list-search">
        <input type="search" placeholder="Zoeken..." onInput={(e) => setFilter(e.target.value)}/>
      </div>
      <div id="org-list-inner">
          {orgs.length === 0 ? <p> Loading... </p> : orgs.filter(filterFunc).filter(org => org.name.toLowerCase().includes(filter.toLowerCase()))
          .map(org => <OrgButton link={link.replace(':org', org.id)} key={org.id} org={org} />)}
      </div>
      <div id="org-list-add">
        <button onClick={ async () => nav('/spider/add') }>Toevoegen</button>
      </div>
    </>
  )
}

export default OrgButtons