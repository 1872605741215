import { useEffect, useState } from 'react'
import ApiService from '../../../services/ApiService';
import './ConfigContent.css'

export const ConfigContent = ({ org }) => {

    const [config, setConfig] = useState({});
    useEffect(() => {
        ApiService.getConfig(org.id, setConfig);
    }, [org]);

    const [scope, setScope] = useState('');
    const [field, setField] = useState('');
    const [value, setValue] = useState('');

    const passedScopes = [];
    const passedFields = [];

  return (
    <>
        <div id="config-content">
            <table>
                <thead>
                    <tr>
                        <th>Scope</th>
                        <th>Field</th>
                        <th>Value</th>
                    </tr>
                    <tr>
                        <th>
                            <input onChange={(e) => setScope(e.target.value)}></input>
                        </th>
                        <th>
                            <input onChange={(e) => setField(e.target.value)}></input>
                        </th>
                        <th>
                            <input onChange={(e) => setValue(e.target.value)}></input>
                        </th>
                        <th>
                            <i onClick={(e) => {
                                const newConfig = { ...config };
                                if (!newConfig[scope]) newConfig[scope] = {};
                                if (!newConfig[scope][field]) newConfig[scope][field] = [];
                                newConfig[scope][field].push(value);
                                setConfig(newConfig);
                            }} className="fa fa-plus"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {config ? Object.entries(config).map(([scope, values1]) => Object.entries(values1).map(([field, values2]) => values2.map(value => 
                            {

                                const scopePassed = passedScopes.includes(scope);
                                if (!scopePassed) passedFields.length = 0;
                                const fieldPassed = passedFields.includes(field);

                                const tr = <tr key={scope + field + value}>
                                    { scopePassed ? <></> : <td rowSpan={countElements(values1)}>{scope}</td> }
                                    { fieldPassed ? <></> : <td rowSpan={countElements(values2)}>{field}</td> }
                                    <td>{value}</td>
                                    <td><i onClick={(e) => {
                                        const newConfig = { ...config };
                                        newConfig[scope][field].splice(newConfig[scope][field].indexOf(value), 1);
                                        setConfig(newConfig);
                                    }} className="fa fa-trash"></i></td>
                                </tr>

                                if (!scopePassed) passedScopes.push(scope);
                                if (!fieldPassed) passedFields.push(field);

                                return tr;
                            }
                        ))) : <>Loading...</>}
                </tbody>
            </table>
        </div>
        <button onClick={() => {
            ApiService.sendConfig(org.id, config, () => {})
        }} id="save-config" className="org-button">Save</button>
    </>
  )
}

function countElements(col) {
    if (col instanceof Array) return col.length;
    else if (col instanceof Object) return Object.entries(col).map(([k, v]) => countElements(v)).reduce((partialSum, a) => partialSum + a, 0);
    console.log('??');
    return 0;
}

export default ConfigContent;