import './Spider.css'
import OrgButtons from './org-buttons/OrgButtons'
import { useLocation } from 'react-router-dom'
import ApiService from '../../services/ApiService'
import { useState, useEffect } from 'react'
import GlobalSpider from './global-spider/GlobalSpider'

function filterFunction(org) {
  return org.can_be_spidered || 'script' in org
}

export const Spider = () => {

  const orgId = parseInt(new RegExp('/spider/(?<slug>[^/#?]+)').exec(useLocation().pathname)?.groups.slug ?? 0);

  const [test, setTest] = useState(false);
  const [onlyRelevant, setOnlyRelevant] = useState(false);

  const [urls, setUrls] = useState([]);
  const [loadingUrls, setLoadingUrls] = useState(false);

  const [org, setOrg] = useState({});
  useEffect(() => {
    if (orgId) ApiService.getOrg(orgId, setOrg)
  }, [orgId]);

  return (
    <div id="spider" className="sc-flex">
      <div id="org-list">
        <OrgButtons link="/spider/:org" filterFunc={filterFunction} />
      </div>
      <div className="main-content">
        <div id="global-spider-control-container">
          <GlobalSpider />
        </div>
        <div id="main-content-inner">
          {orgId && org ? 
            <>
              <h1>{org.name}</h1>
              <div id="inputs">
                <div id="test-container" className="checkbox-container">
                  <label htmlFor="test-check">Test: </label>
                  <input onChange={ (e) => setTest(e.target.checked) } type="checkbox" id="test-check"></input>
                </div>
                <div id="only-relevant-container" className="checkbox-container">
                  <label htmlFor="only-relevant-check">Alleen relevante vacatures: </label>
                  <input onChange={ (e) => setOnlyRelevant(e.target.checked) } type="checkbox" id="only-relevant-check"></input>
                </div>
              </div>
              <button onClick={ () => {
                  if (test) setLoadingUrls(true);
                  ApiService.runOrgSpider((json) => {
                    setUrls(json);
                    setLoadingUrls(false);
                  }, orgId, test, onlyRelevant);
                } } id="post-btn" className="btn"> Plaatsen </button>
              <div id="text-area-container">
                {loadingUrls ? <textarea value="Loading..." readOnly></textarea> : <></>}
                {!loadingUrls && urls.length > 0 ? <textarea value={urls.join('\n')} readOnly></textarea> : <></>}
              </div>
            </> : <></>
          }
        </div>
      </div>
    </div>
  )
}

export default Spider