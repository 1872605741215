import { Link } from 'react-router-dom'
import './NavButton.css'

export const NavButton = ({ text, path, nested }) => {
  if (nested === undefined) nested = [];
  return (
    <Link to={path}>
        <div className="nav-item-inner">
            <b>{text}</b>
        </div>
    </Link>
  )
}

export default NavButton