import { useState } from 'react'
import ApiService from '../../../services/ApiService'
import './GlobalSpider.css'

export const GlobalSpider = () => {

    const [test, setTest] = useState(false)
    const [sync, setSync] = useState(true)

  return (
    <>
        <button onClick={(e) => {
            ApiService.runSpider(() => {

            }, test, sync)
        }}>Run Spider</button>
        <div id="global-spider-inputs">
            <label htmlFor="gs-test-checkbox">Test: </label>
            <input onChange={(e) => { setTest(e.target.checked) }} id="gs-test-checkbox" type="checkbox"/>
            <label htmlFor="gs-sync-checkbox">Sync: </label>
            <input onChange={(e) => { setSync(e.target.checked) }} id="gs-sync-checkbox" type="checkbox" defaultChecked/>
        </div>
    </>
  )
}

export default GlobalSpider