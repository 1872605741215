import React from 'react'
import TextAreaInput from '../../../components/text-area-input/TextAreaInput'
import ApiService from '../../../services/ApiService'

export const SpiderBlacklist = () => {

    const handleClick = (text) => ApiService.blacklistSpiderUrls(text.split(/\n/));

  return (
    <TextAreaInput buttonText="Blacklist" title="Blacklist hier urls in de spider" onClick={handleClick}/>
  )
}

export default SpiderBlacklist