import './Header.css'
import NavSection from './nav-section/NavSection'

export const Header = () => {
  return (
    <header>
        <div id="navigation">
            <NavSection text='Home' path='/'/>
            <NavSection text='Plaatsen' path='/plaatsen'/>
            <NavSection text='Spider' path='/spider' nested={{
              'Blacklist': '/spider/blacklist'
            }}/>
            <NavSection text='Config' path='/config'/>
            <NavSection text='Dashboard' path='/spider/dashboard'/>
            <NavSection text='Logout' path='/logout'/>
        </div>
    </header>
  )
}

export default Header