import './Config.css'
import OrgButtons from '../spider/org-buttons/OrgButtons'
import { useLocation } from 'react-router-dom'
import ApiService from '../../services/ApiService'
import { useState, useEffect } from 'react'
import ConfigContent from './config-content/ConfigContent'

export const Config = () => {

    const orgId = parseInt(new RegExp('/config/(?<slug>[^/#?]+)').exec(useLocation().pathname)?.groups.slug ?? 0);

    const [org, setOrg] = useState({});
    useEffect(() => {
        if (orgId) ApiService.getOrg(orgId, setOrg)
    }, [orgId]);

  return (
    <div id="config" className="sc-flex">
        <div id="org-list">
            <OrgButtons link="/config/:org" />
        </div>
        <div className="main-content">
            { orgId ?
            <>
                <h1>{org.name}</h1>
                <ConfigContent org={org}/>
            </>
            : <></> }
        </div>
    </div>
  )
}

export default Config;