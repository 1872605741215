import './TextAreaInput.css'
import { useState } from 'react'

export const TextAreaInput = ({ buttonText, onClick, title }) => {

    const [content, setContent] = useState('')

  return (
    <div id="container">
        <h1>{title}</h1>
        <textarea onChange={(e) => setContent(e.target.value)}></textarea>
        <div id="btn-container">
            <button onClick={() => onClick(content)} id="btn"> {buttonText} </button>
        </div>
    </div>
  )
}

export default TextAreaInput;