import './SpiderDashboard.css';
import React, { useEffect, useState } from 'react'
import ApiService from '../../../services/ApiService';

export const SpiderDashboard = () => {
    const [content, setContent] = useState([])

    useEffect(() => {
        ApiService.getSpiderDashboard(setContent)
    }, [])

  return (
    <div id="dashboard">
        <div id="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>URL</th>
                        <th>Laatst gevonden</th>
                        <th>Laatst doorgestuurd</th>
                    </tr>
                </thead>
                <tbody>
                    { content.filter((org) => org.is_spidered).sort((a, b) => a.last_found - b.last_found).map((org) => {
                        return <tr key={org.id}>
                            <td> { org.name } </td>
                            <td><a href={ org.url }> { org.url } </a></td>
                            <td> { org.last_found } </td>
                            <td> { org.last_forwarded } </td>
                        </tr>
                    }) }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default SpiderDashboard;