const base = 'https://api.dcmp.simon-holman.nl'
const post = base + '/vacancies/post'
const orgs = base + '/org/'
const spiderOrg = base + '/spider/run'
const spiderBlacklist = base + '/spider/blacklist/urls'
const spider = base + '/spider/run-spider'
const spiderDashboard = base + '/spider/overview'
const auth = base + '/authenticate';
let invalidTokenHandler = () => ( console.log('oh no...') );

const data = {
    orgs: []
}

function getToken() {
    return localStorage.getItem('TOKEN') ?? '';
}

function setToken(token) {
    localStorage.setItem('TOKEN', token);
}

function getPayload(jwt) {
    try {
        return JSON.parse(atob(jwt.split('.')[1]));
    } catch (err) {
        return { exp: 0 }
    }
}

function isExpired(jwt) {
    return parseInt(getPayload(jwt).exp) < new Date().getTime() / 1000;
}

const ApiService = {

    login: function(username, password, onSuccess, onFailure) {
        send(auth, 'POST', { username, password }, 
        (json) => {
            setToken(json.token);
            onSuccess();
        }, onFailure);
    },

    getRole: function() {
        return getPayload(getToken()).role
    },

    hasValidToken: function() {
        return !isExpired(getToken());
    },

    setInvalidTokenHandler: function(cb) {
        invalidTokenHandler = cb;
    },

    post: function(urls) {
        send(post, 'POST', JSON.stringify({ urls }));
    },

    runOrgSpider: function(cb, orgId, test = false, onlyRelevant = false) {
        send(spiderOrg, 'POST', {
            org_id: orgId,
            only_relevant: onlyRelevant,
            test: test
        }, cb)
    },

    runSpider: function(cb, test = false, sync = true) {
        send(spider + `?test=${test ? 'true' : 'false'}&with_sync=${sync ? 'true' : 'false'}`, 'POST', null, cb)
    },

    blacklistSpiderUrls: function(urls) {
        send(spiderBlacklist, 'POST', JSON.stringify(urls))
    },

    getOrgs: function(cb) {
        if (data.orgs.length) cb(data.orgs);
        else send(orgs, 'GET', null, json => {
            data.orgs = json;
            cb(json);
        });
    },

    getOrg: function(orgId, cb) {
        if (orgId) send(orgs + orgId, 'GET', null, cb)
    },

    getConfig: function(orgId, cb) {
        if (orgId) send(orgs + orgId + '/config', 'GET', null, cb)
    },

    sendConfig: function(orgId, config, cb) {
        if (orgId) send(orgs + orgId + '/config', 'POST', config, cb)
    },

    getSpiderDashboard: function(cb) {
        send(spiderDashboard, 'GET', null, cb)
    }
}

function send(path, method = 'GET', body = null, onSuccess = console.log, onFailure = console.log) {
    let init = {
        method: method ?? 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    if (path !== auth) init.headers['Authorization'] = 'Bearer ' + getToken();
    if (body) init.body = body instanceof Object ? JSON.stringify(body) : body;
    fetch(path, init).then(res => {
        if (res.ok) return res.json();
        else if (res.status === 401) invalidTokenHandler();
        else return Promise.reject();
    }).then(onSuccess).catch(err => {
        onFailure(err);
    });
}

export default ApiService
