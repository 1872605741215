import './App.css';
import Header from './header/Header'
import Home from './content/home/Home'
import Plaatsen from './content/plaatsen/Plaatsen'
import Spider from './content/spider/Spider'
import Config from './content/config/Config'
import Login from './login/Login'
import ApiService from './services/ApiService';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useState } from 'react'
import SpiderBlacklist from './content/spider/spider-blacklist/SpiderBlacklist';
import SpiderDashboard from './content/spider/dashboard/SpiderDashboard';

function App() {

  const [loggedIn, setLoggedIn] = useState(ApiService.hasValidToken());
  ApiService.setInvalidTokenHandler(() => {
    console.log("token verlopen/fout")
    setLoggedIn(false)
  });

  return (
    <BrowserRouter>
      <Header />
      <div id="bg"></div>
      <div className="content">
        { loggedIn ?
          <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/plaatsen" element={<Plaatsen />}></Route>
          <Route exact path="/spider" element={<Spider />}></Route>
          <Route exact path="/spider/:org" element={<Spider />}></Route>
          <Route exact path="/spider/blacklist" element={<SpiderBlacklist />}></Route>
          <Route exact path="/spider/dashboard" element={<SpiderDashboard />}></Route>
          <Route exact path="/config" element={<Config />}></Route>
          <Route exact path="/config/:org" element={<Config />}></Route>
        </Routes> :
        <Login setLoggedIn={setLoggedIn} />
        }
      </div>
    </BrowserRouter>
  );
}

export default App;
