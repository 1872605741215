import './Login.css'
import ApiService from '../services/ApiService';
import { useState } from 'react'

export const Login = ({ setLoggedIn }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

  return (
    <div id="login-container">
        <div id="login-container-inner">
            <h2>Login</h2>
            <label>Username:</label>
            <input type="text" onChange={(e) => setUsername(e.target.value)}/>
            <label>Password:</label>
            <input type="password" onChange={(e) => setPassword(e.target.value)}/>
            <button onClick={() => { 
                ApiService.login(username, password, () => { 
                    console.log("yyet")
                    setLoggedIn(true)
                 }, () => { alert('Inloggen mislukt') })
             }}>Login</button>
        </div>
    </div>
  )
}

export default Login;