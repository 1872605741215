import { useNavigate } from 'react-router-dom'

export const OrgButton = ({ org, link }) => {

  let nav = useNavigate()

  return (
    <button onClick={ async () => nav(link) } id={"org-"+org.id} className="org-button">{org.name}</button>
  )
}

export default OrgButton