/*import './Plaatsen.css'*/
import ApiService from '../../services/ApiService'
import TextAreaInput from '../../components/text-area-input/TextAreaInput'

export const Plaatsen = () => {

    const handlePlaatsenClick = (text) => ApiService.post(text.split(/\n/));


  return (
    <TextAreaInput buttonText='Plaats' title='Plaats hier vacatures' onClick={handlePlaatsenClick}/>
  )
}

export default Plaatsen